import React, {Fragment} from 'react';
import {Col, Container, Row, Button} from 'reactstrap';
import ReactMarkdown from 'react-markdown';

import {usePhaseState} from 'funnel-schedule/phase-context';
import useBalanceText from 'utils/useBalanceText';

import PageMeta from 'components/PageMeta';
import Header from 'components/Elements/Header';
import Section from 'components/Elements/Section';
import Footer from 'components/Elements/Footer';

//@ts-ignore
import {supportTeam} from 'data/pbcc-content.json';
//@ts-ignore
import {speakers} from 'data/module.json';

export default function ClarityCallsIndex() {
  const {
    phases: {
      default: defaultSchedule = {
        phase: 'before'
      }
    }
  } = usePhaseState();

  const pageTitle =
    'Change the way the world eats with Food Revolution Network’s Plant-Based Coaching Certification Program';
  const pageDescription = 'Get everything you need to transform your clients’ lives and your life.';
  const ogImage = 'https://cdn.foodrevolution.org/pbcc/og/pbcc-open-graph-1200x1200-20221004.png';
  const shareUrl = 'https://certification.foodrevolution.org/join/';
  const facebookDescription =
    'Do what you love and make a difference with the world’s only plant-based health coaching program to combine nutrition, coaching skills, and business building. Learn more here:';
  const twitterDescription =
    'Do what you love and make a difference with the world’s only plant-based health coaching program to combine nutrition, coaching skills, and business building. Learn more here:';
  const twitterImg = 'https://cdn.foodrevolution.org/pbcc/og/pbcc-open-graph-1200x675-20221004.png';
  const pinterestDescription =
    'Do what you love and make a difference with the world’s only plant-based health coaching program to combine nutrition, coaching skills, and business building. Learn more here:';
  const pinterestImg = 'https://cdn.foodrevolution.org/pbcc/og/pbcc-open-graph-1000x1500-20221004.png';

  useBalanceText();
  return (
    <div className="page d-flex flex-column page-nosocial">
      <PageMeta title={pageTitle} description={pageDescription} url={shareUrl} ogImage={ogImage} />
      <div className="page-content">
        <Header
          bgColor="black"
          lockupWhite
          logoUrlOverride="https://cdn.foodrevolution.org/pbcc/pbcc-logo-white.svg"
          logoAltOverride="plant-based coaching logo"
          logoWidthOverride={300}
          logoHeightOverride={59}
          shareUrl={shareUrl}
          shareImg={ogImage}
          facebookDescription={facebookDescription}
          twitterDescription={twitterDescription}
          pinterestDescription={pinterestDescription}
          pinterestImage={pinterestImg}
        />
        <Section id="pbcc-support-header" color="white" className="text-center" angle="none">
          <Container>
            <Row>
              <Col>
                <div className="background-light-gray rounded box-shadow-black-50 p-4 p-lg-5">
                  <h1 className="section-heading text-h2 mb-3">Book Your Call for Live Student Support</h1>
                  <p>Have questions about FRN’s Plant-Based Coaching Certification Program?</p>
                  <p className="balance-text">
                    If you’ve already emailed us at{' '}
                    <a href="mailto:certification@foodrevolution.org" target="_blank">
                      certification@foodrevolution.org
                    </a>
                    , rest assured your message is being reviewed in the order it was received. Our team processes
                    inquiries Tuesday through Friday each week, and we appreciate your patience.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section id="pbcc-support-book" color="purple" className="section-angle-last" angle="top">
          <Container>
            <Row>
              <Col>
                <div className="text-center text-white">
                  <h2 className="section-heading">Prefer to speak with someone directly?</h2>
                  <p className="balance-text text-h5">
                    When booking your call, please let us know the reason for your inquiry. This helps us connect you
                    with the best person to assist and ensures we’re fully prepared to address your needs — especially
                    if your request requires coordination with our team behind the scenes.
                  </p>
                  <h3>Our student support team</h3>
                </div>
                <Row className="d-flex align-items-stretch justify-content-center">
                  {supportTeam.map((advisor, i) => {
                    return (
                      <Fragment key={advisor + i}>
                        {speakers
                          .filter((speaker) => speaker.id === advisor.id)
                          .map((speaker) => {
                            return (
                              <Col key={speaker.id} md="6" className="d-flex flex-column flex-grow-1 mb-4 mb-md-0">
                                <div className="background-white rounded box-shadow-black-50 d-flex flex-column flex-grow-1">
                                  <div
                                    className="clarity-call-image"
                                    style={{backgroundImage: `url('${speaker.image}')`}}
                                  />
                                  <div className="clarity-call-detail p-3 flex-grow-1 d-flex flex-column">
                                    <h5 className="section-heading text-center mb-1">{speaker.name}</h5>
                                    {speaker.taglineSupportTeam && (
                                      <p className="balance-text text-center mb-0">{speaker.taglineSupportTeam}</p>
                                    )}
                                    <ReactMarkdown className="text-16 mt-3 mb-4" source={speaker.bioSupportTeam} />
                                    <p className="text-center mt-auto mb-0">
                                      <Button
                                        className="text-uppercase"
                                        color="cta mt-4"
                                        href={speaker.supportTeamLink}
                                        target="_blank"
                                        block
                                      >
                                        Book My Student Support <br className="d-none d-xl-block" />
                                        Call with {speaker.firstName}
                                      </Button>
                                    </p>
                                  </div>
                                </div>
                              </Col>
                            );
                          })}
                      </Fragment>
                    );
                  })}
                </Row>
                <p className="text-center text-white mt-5">
                  We’re committed to providing the best support possible and look forward to assisting you!
                </p>
              </Col>
            </Row>
          </Container>
        </Section>
      </div>
      <div className="footer-content mt-auto">
        <Footer />
      </div>
    </div>
  );
}
