import React, {useEffect, useState} from 'react';
import {Container, Col, Row} from 'reactstrap';
import classNames from 'classnames';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';

import {usePhaseState} from 'funnel-schedule/phase-context';
import useBalanceText from 'utils/useBalanceText';

import PageMeta from 'components/PageMeta';
import Header from 'components/Elements/Header';
import {SectionTriangle, SectionTriangleSpeakerCollapse} from 'components/Elements/Section';
import {ListIcon} from 'components/Elements/IconList';
import Video from 'components/Video';
import OptinModal, {OptinModalTrigger} from 'components/OptinModal';
import ModalExitMasterclassLanding from 'components/Modals/ModalExitMasterclassLanding';
import Footer from 'components/Elements/Footer';
import CountdownTimer from 'components/Elements/CountdownTimer';

export default function MasterclassIndex({variant = 'default', noFixedVideo = false, noExitIntent = false, ...props}) {
  const pageTitle = 'Free Masterclass on Becoming a Plant-Based Coach';
  const pageDescription =
    'Learn how to help people reach their health goals — improving human lives and our planet for generations to come.';
  const ogImage = 'https://cdn.foodrevolution.org/pbcc/og/pbcc-2024-open-graph-masterclass-1200x1200.png';
  const shareUrl = 'https://certification.foodrevolution.org/masterclass/';
  const facebookDescription =
    'Ready to learn how to help people eat healthy & spread the food revolution as a Plant-Based Coach? Register for free here:';
  const twitterDescription =
    'Ready to learn how to help people eat healthy & spread the food revolution as a Plant-Based Coach? Register for free here:';
  const twitterImg = 'https://cdn.foodrevolution.org/pbcc/og/pbcc-2024-open-graph-masterclass-1200x675.png';
  const pinterestDescription =
    'Ready to learn how to help people eat healthy & spread the food revolution as a Plant-Based Coach? Pin to inspire others to join the movement! Register for free here:';
  const pinterestImg = 'https://cdn.foodrevolution.org/pbcc/og/pbcc-2024-open-graph-masterclass-1000x1500.png';

  const {
    phases: {
      masterclass: masterclassSchedule = {
        phase: 'before'
      }
    }
  } = usePhaseState();

  const beforeMasterclassLaunch = masterclassSchedule.phase === 'beforeLaunch';
  const registrationClosed = masterclassSchedule.phase === 'after';
  console.log('RegistrationClosed:' + registrationClosed);

  const [footerSectionControl, setFooterSectionControl] = useState(props.footerSectionControl || 'control');
  useEffect(() => {
    subscribeToCampaign((variantIndex) => {
      switch (variantIndex) {
        case 1:
          setFooterSectionControl('v1');
          break;
      }
    }, 884);
  }, []);
  const ctaButtonCopy = <>Save My Spot</>;
  const headerImage = 'https://cdn.foodrevolution.org/pbcc/images/1454585781-lg.jpg';

  const thumbUrl = 'https://cdn.foodrevolution.org/pbcc/video-thumb/pbcc-masterclass-dynamic-graphic-20231101.gif';
  const thumbAlt = 'animated graphic with words do you want to help the way the world eats?';

  const timerDate = new Date(masterclassSchedule.timerDate).getTime();
  function Timer({className}) {
    return (
      <div className={className}>
        <h5 className="text-400 mt-0 mb-2">{masterclassSchedule.timerHeadline}</h5>
        <CountdownTimer time={timerDate} className="countdown-dark" />
      </div>
    );
  }

  useBalanceText();
  return (
    <div className={classNames(`page`, `page-nosocial`, `d-flex`, `flex-column`)}>
      <PageMeta title={pageTitle} description={pageDescription} url={shareUrl} ogImage={ogImage} />

      <div
        className={classNames(
          `page-content`,
          beforeMasterclassLaunch || registrationClosed ? `d-flex flex-column flex-grow-1` : null
        )}
      >
        <Header
          bgColor="white"
          textColor="black"
          logoUrlOverride="https://cdn.foodrevolution.org/pbcc/pbcc-logo-masterclass.svg"
          logoAltOverride="plant-based coaching masterclass logo"
          logoWidthOverride={300}
          logoHeightOverride={59}
          shareUrl={shareUrl}
          shareImg={ogImage}
          facebookDescription={facebookDescription}
          twitterDescription={twitterDescription}
          pinterestDescription={pinterestDescription}
          pinterestImage={pinterestImg}
        />

        {beforeMasterclassLaunch ? (
          <SectionTriangle
            id="pbccm-header"
            color="black"
            angle="none"
            className="section-angle-first flex-grow-1"
            innerClass="my-auto py-0"
            imgUrl={headerImage}
          >
            <Container>
              <Row className="justify-content-center">
                <Col lg="10">
                  <div className="background-light-gray rounded box-shadow-black-50 my-5">
                    <div className="background-black rounded-top px-3 py-4 text-white text-center">
                      <h2 className="section-only-heading">Coming Soon!</h2>
                    </div>
                    <div className="text-center p-4 p-lg-5">
                      <img
                        className="my-3"
                        src="https://cdn.foodrevolution.org/pbcc/pbcc-logo-masterclass.svg"
                        alt="plant-based coaching masterclass logo"
                        width={600}
                        height={118}
                      />
                      <p className="balance-text mb-4">
                        Join best-selling author Ocean Robbins; master health coach Tracy Garrigan; plant-based
                        dietitian Nichole Dandrea-Russert, RDN, & thousands of food revolutionaries.
                      </p>
                      <p className="balance-text mb-4">
                        <b>
                          In under 90 minutes, you’ll gain actionable insights and coaching techniques to make a
                          meaningful impact and contribute to a healthier world.
                        </b>
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </SectionTriangle>
        ) : registrationClosed ? (
          <SectionTriangle
            id="pbccm-header"
            color="black"
            angle="none"
            className="section-angle-first flex-grow-1"
            innerClass="my-auto py-0"
            imgUrl={headerImage}
          >
            <Container>
              <Row className="justify-content-center">
                <Col lg="10">
                  <div className="background-light-gray rounded box-shadow-black-50 my-5">
                    <div className="text-center p-4 p-lg-5">
                      <h1 className="text-h3 mt-0">
                        Sorry — The Plant-Based Coaching Masterclass is no longer open for registrations.
                      </h1>
                      <p>
                        <a href="https://forms.gle/YdtMAuTxJEph1LaQ7" target="_blank">
                          Click here to be notified if we offer this program again in the future.
                        </a>
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </SectionTriangle>
        ) : (
          <>
            <SectionTriangle
              id="pbccm-header"
              color="black"
              angle="none"
              className="section-angle-first section-triangle-offset-bottom"
              imgUrl={headerImage}
            >
              <Container>
                <Row className="justify-content-center">
                  <Col lg="10">
                    <div className="background-light-gray rounded box-shadow-black-50 p-4 p-lg-5 text-center">
                      <h5 className="section-heading mb-0">
                        <i>Limited Time Only: Your Free Ticket to Transforming Lives</i>
                      </h5>
                      {masterclassSchedule.phase === 'during' && <Timer className="mt-3" />}
                      <img
                        className="my-3"
                        src="https://cdn.foodrevolution.org/pbcc/pbcc-logo-masterclass.svg"
                        alt="plant-based coaching masterclass logo"
                        width={600}
                        height={118}
                      />
                      <p className="balance-text mb-4">
                        Join bestselling author Ocean Robbins; master health coach Tracy Garrigan; plant-based dietitian
                        Nichole Dandrea-Russert, RDN, & thousands of food revolutionaries.
                      </p>
                      <p className="balance-text mb-4">
                        <b>
                          In under 90 minutes, you’ll gain actionable insights and coaching techniques to make a
                          meaningful impact and contribute to a healthier world.
                        </b>
                      </p>
                      <OptinModalTrigger>{ctaButtonCopy}</OptinModalTrigger>
                    </div>
                  </Col>
                </Row>
              </Container>
            </SectionTriangle>

            <SectionTriangle id="pbccm-video" color="white" type="both">
              <Container>
                <Row className="justify-content-center text-center mb-5">
                  <Col xs="12" lg="10" className="video-col px-0 px-sm-3">
                    <img className="w-100" src={thumbUrl} alt={thumbAlt} width={600} height={338} />
                  </Col>
                </Row>

                <Row className="justify-content-center">
                  <Col lg="11">
                    <h3 className={classNames(`section-heading`, `text-center`, `mt-4`)}>
                      Join us for this Masterclass and learn:
                    </h3>
                    <div className="d-flex justify-content-center">
                      <ListIcon className="m-0">
                        <>
                          {
                            {
                              default: (
                                <>
                                  <h5 className="section-heading text-green mb-1">
                                    The top three reasons that a whole food, plant-based diet could be critical
                                  </h5>
                                  <p className="balance-text mb-0">
                                    to the survival of our species — and why coaches who specialize in this area can
                                    thrive in the coming decades.
                                  </p>
                                </>
                              ),
                              ad: (
                                <>
                                  <h5 className="section-heading text-green mb-1">
                                    The top three reasons that a whole food, plant-based diet has exploded in recent
                                    years
                                  </h5>
                                  <p className="balance-text mb-0">
                                    — and why coaches who specialize in this area can thrive in the coming decades.
                                  </p>
                                </>
                              )
                            }[variant]
                          }
                        </>
                        <>
                          <h5 className="section-heading text-green mb-1">What most coaches fail to do</h5>
                          <p className="balance-text mb-0">
                            to keep their clients from backsliding, and{' '}
                            <b>
                              what to do instead to help people adopt healthy eating habits that can transform their
                              lives.
                            </b>
                          </p>
                        </>
                        <>
                          <h5 className="section-heading text-green mb-1">
                            How to help people avoid the three biggest mistakes
                          </h5>
                          <p className="balance-text mb-0">
                            <b>that many plant-based eaters make</b> that could mean they miss out on critical nutrients
                            and wind up sick.
                          </p>
                        </>
                        <>
                          {
                            {
                              default: (
                                <>
                                  <h5 className="section-heading text-green mb-1">
                                    The top four keys to success as a plant-based coach
                                  </h5>
                                  <p className="balance-text mb-0">
                                    and how to help people make positive changes in their diet without feeling
                                    defensive, guilty, or nagged.
                                  </p>
                                </>
                              ),
                              ad: (
                                <>
                                  <h5 className="section-heading text-green mb-1">
                                    The top four keys to success as a plant-based coach
                                  </h5>{' '}
                                  <p className="balance-text mb-0">
                                    and how to help people make positive changes without feeling defensive, guilty, or
                                    nagged.
                                  </p>
                                </>
                              )
                            }[variant]
                          }
                        </>
                        <>
                          <h5 className="section-heading text-green mb-1">
                            The stunning opportunities that are opening up fast in the field
                          </h5>
                          <p className="balance-text mb-0">
                            of plant-based coaching and how to find the pathway that’s best for you.
                          </p>
                        </>
                        <>
                          <h5 className="section-heading text-green mb-1">
                            How some coaches get new clients effortlessly —
                          </h5>
                          <p className="balance-text mb-0">
                            without needing a huge marketing budget or a fancy website.
                          </p>
                        </>
                      </ListIcon>
                    </div>
                  </Col>
                </Row>
              </Container>
            </SectionTriangle>

            <SectionTriangle id="pbccm-instructor" color="green" className="text-center text-white" type="bottom">
              <Container>
                <Row>
                  <Col>
                    <h2 className="section-only-heading">Meet Your Instructors</h2>
                  </Col>
                </Row>
              </Container>
            </SectionTriangle>

            <SectionTriangleSpeakerCollapse
              id="pbccm-ocean"
              color="white"
              imgPosition="right"
              imgUrl="https://cdn.foodrevolution.org/global/team/frn-guest_expert-portrait-square-ocean_robbins.jpg"
              collapseBgColor="green"
              headlines={
                <>
                  <h3 className="section-heading mb-2">Ocean Robbins</h3>
                  <h5 className="m-0 text-400">Food Revolution Network CEO and bestselling author.</h5>
                </>
              }
              collapseHeader={
                <>
                  <h5 className="text-400 m-0">Click Here to</h5>
                  <h4 className="m-0">Learn More About Ocean Robbins</h4>
                </>
              }
              content={
                <>
                  <p>
                    Ocean Robbins, co-founder and CEO of the million-member Food Revolution Network, is a TEDx speaker,
                    best-selling author, husband, father, gardener, and dancer. Born in a log cabin and raised on
                    home-grown food, his work is rooted in his family’s health-conscious legacy. From founding the
                    nonprofit YES! at age 16 to leading a global food revolution movement, Ocean is on a mission to
                    transform our food system, drive down the chronic disease epidemic, and mobilize people worldwide to
                    be part of the solution for a healthier planet and population. His books include{' '}
                    <i>31-Day Food Revolution</i> and <i>Powered By Plants.</i>
                  </p>
                </>
              }
            />
            <SectionTriangleSpeakerCollapse
              id="pbccm-tracy"
              color="light-gray"
              imgPosition="left"
              imgUrl="https://cdn.foodrevolution.org/global/team/frn-guest_expert-portrait-square-tracygarrigan.jpg"
              collapseBgColor="green"
              headlines={
                <>
                  <h3 className="section-heading mb-2">Tracy Garrigan, CHHC</h3>
                  <h5 className="m-0 text-400">
                    Certified Holistic Health Coach and founder of the Plant-Based Coaching Certification
                  </h5>
                </>
              }
              collapseHeader={
                <>
                  <h5 className="text-400 m-0">Click Here to</h5>
                  <h4 className="m-0">Learn More About Tracy Garrigan</h4>
                </>
              }
              content={
                <p>
                  For 10 years, Tracy Garrigan, CHHC, has coached private clients around the world. She has helped
                  deliver performance nutrition programs to fitness facilities, corporate wellness programs to people in
                  the workplace, and has created content that helps digital platforms deliver nutrition and health
                  education to the masses. Tracy has partnered with medical wellness centers and practitioners to offer
                  Health Coaching to hundreds of patients for improved health outcomes. She serves as FRN’s Lead Coach
                  and is the founder of the Plant-Based Coaching Certification.
                </p>
              }
            />
            <SectionTriangleSpeakerCollapse
              id="pbccm-nichole"
              color="white"
              imgPosition="right"
              imgUrl="https://cdn.foodrevolution.org/global/team/nichole-d.jpg"
              collapseBgColor="green"
              headlines={
                <>
                  <h3 className="section-heading mb-2">Nichole Dandrea-Russert, MS, RDN</h3>
                  <h5 className="m-0 text-400">
                    Recipe Developer, author, and Food Revolution Network’s Lead Dietitian
                  </h5>
                </>
              }
              collapseHeader={
                <>
                  <h5 className="text-400 m-0">Click Here to</h5>
                  <h4 className="m-0">Learn More About Nichole Dandrea-Russert</h4>
                </>
              }
              content={
                <p>
                  Nichole Dandrea-Russert, MS, RDN, is Food Revolution Network’s Lead Dietitian and Recipe Developer.
                  She specializes in women’s health, heart disease, and diabetes. She is the author of{' '}
                  <i>The Fiber Effect,</i> <i>The Vegan Athlete’s Nutrition Handbook,</i> and coauthor (with Ocean
                  Robbins) of <i>Real Superfoods: Everyday Ingredients to Elevate Your Health.</i>
                </p>
              }
            />

            <SectionTriangle id="pbccm-shareclass" color="black" className="text-center text-white" type="bottom">
              <Container>
                <Row>
                  <Col>
                    <h2 className="section-heading">
                      Ocean, Tracy, and Nichole can’t wait to share this Masterclass with you.
                    </h2>
                    <OptinModalTrigger>{ctaButtonCopy}</OptinModalTrigger>
                  </Col>
                </Row>
              </Container>
            </SectionTriangle>

            <SectionTriangle id="pbccm-difference" color="white" type="bottom" className="text-center">
              <Container>
                <Row>
                  <Col>
                    <p className="balance-text text-h5">
                      What if you could <b>START and FINISH</b> every day knowing you’re <b>making a difference</b> in
                      the lives of people, animals, and the planet?
                    </p>
                    <p className="balance-text text-h5">
                      What if you had <b>the skills</b> to help other people <b>get and stay healthy?</b>
                    </p>
                    <p className="balance-text text-h5">
                      {
                        {
                          default: (
                            <>
                              What if you knew the <b>insider techniques to</b> help people choose{' '}
                              <b>food that healed them</b> — even in the face of long-standing habits or social
                              pressure?
                            </>
                          ),
                          ad: (
                            <>
                              What if you knew the <b>insider techniques to</b> help people{' '}
                              <b>make smart food choices</b> — even in the face of long-standing habits or social
                              pressure?
                            </>
                          )
                        }[variant]
                      }
                    </p>
                  </Col>
                </Row>
              </Container>
            </SectionTriangle>

            {
              {
                control: (
                  <SectionTriangle id="pbccm-cta-final" color="light-gray" className="text-center">
                    <Container>
                      <Row>
                        <Col>
                          <div className="background-white rounded box-shadow-black-50 p-4 p-lg-5">
                            <h2 className="section-heading text-green mb-0">
                              <i>Don’t miss this groundbreaking</i>
                            </h2>
                            <img
                              className="my-4"
                              src="https://cdn.foodrevolution.org/pbcc/pbcc-logo-masterclass.svg"
                              alt="plant-based coaching masterclass logo"
                              width={600}
                              height={118}
                            />
                            <h4 className={classNames(`text-green mt-0 mb-3`)}>
                              <i>Absolutely FREE, only 80 minutes, and plays in your local time.</i>
                            </h4>
                            <p className="mb-0">
                              (And, if you can’t watch it all in one setting you can come back to finish it later.)
                            </p>
                            {masterclassSchedule.phase === 'during' && <Timer className="mt-5 mb-4" />}
                            <p className="mt-4 mb-0">
                              <OptinModalTrigger>{ctaButtonCopy}</OptinModalTrigger>
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </SectionTriangle>
                ),
                v1: (
                  <SectionTriangle id="pbccm-cta-final" color="purple" className="text-center text-white">
                    <Container>
                      <Row>
                        <Col>
                          <h2 className="section-heading mb-0">
                            <i>Don’t miss this groundbreaking</i>
                          </h2>
                          <img
                            className="my-4"
                            src="https://cdn.foodrevolution.org/pbcc/pbcc-logo-masterclass-white.svg"
                            alt="plant-based coaching masterclass logo"
                            width={600}
                            height={118}
                          />
                          <h4 className={classNames(`text-green mt-0 mb-3`)}>
                            <i>Absolutely FREE, only 80 minutes, and plays in your local time.</i>
                          </h4>
                          <p className="mb-0">
                            (And, if you can’t watch it all in one setting you can come back to finish it later.)
                          </p>
                          {masterclassSchedule.phase === 'during' && <Timer className="mt-5 mb-4" />}
                          <p className="mt-4 mb-0">
                            <OptinModalTrigger>{ctaButtonCopy}</OptinModalTrigger>
                          </p>
                        </Col>
                      </Row>
                    </Container>
                  </SectionTriangle>
                )
              }[footerSectionControl]
            }
          </>
        )}
      </div>

      {!noExitIntent && !registrationClosed ? <ModalExitMasterclassLanding /> : null}

      <div className="footer-content mt-auto">
        <Footer />
      </div>

      <OptinModal btnText={ctaButtonCopy} variant={variant} masterclass />
    </div>
  );
}
