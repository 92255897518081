import React, {useState} from 'react';
import {Container, Collapse, Navbar, NavbarToggler, Nav, NavItem, NavLink, Button} from 'reactstrap';
import classNames from 'classnames';
import './Header.scss';

export default function Header({
  bgColor = 'purple',
  textColor = 'white',
  lockupWhite = false,
  className = undefined,
  logoUrlOverride = undefined,
  logoAltOverride = undefined,
  logoWidthOverride = undefined,
  logoHeightOverride = undefined,
  adLockup = false,
  shareUrl,
  shareImg,
  facebookDescription,
  twitterDescription,
  twitterHashtags = undefined,
  pinterestDescription,
  pinterestImage,
  ...props
}) {
  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);
  return (
    <header
      className={classNames(`header`, `background-${bgColor}`, `text-${textColor}`, `d-flex`, `flex-column`, className)}
    >
      <Container className={classNames(`headerContainer`, `my-auto`)}>
        <Navbar className={classNames(`headerNavbar`, `p-0`)} expand="lg" container={false}>
          <div className={classNames(`headerNavbarBrand`, `flex-grow-1`)}>
            {logoUrlOverride ? (
              <img
                className={classNames(`headerLogo`)}
                src={logoUrlOverride}
                alt={logoAltOverride}
                width={logoWidthOverride}
                height={logoHeightOverride}
              />
            ) : (
              <div className="d-flex align-items-center">
                <Button
                  color="headerLogo"
                  href="https://join.foodrevolution.org/offerings/"
                  target="_blank"
                  disabled={adLockup ? false : true}
                >
                  <img
                    className={classNames(`headerLogoFRN`)}
                    src={
                      lockupWhite
                        ? 'https://cdn.foodrevolution.org/global/frn-logo-2024-trademarked-white.svg'
                        : 'https://cdn.foodrevolution.org/global/frn-logo-2024-trademarked-color.svg'
                    }
                    alt="Food Revolution Network logo"
                    width={300}
                    height={116}
                  />
                </Button>
                <div className="headerLogoDivider" />
                <img
                  className={classNames(`headerLogoPROD`)}
                  src={
                    lockupWhite
                      ? 'https://cdn.foodrevolution.org/pbcc/pbcc-logo-noicon-white.svg'
                      : 'https://cdn.foodrevolution.org/pbcc/pbcc-logo-noicon-black.svg'
                  }
                  alt="plant-based coaching certification logo"
                  width={300}
                  height={70}
                />
              </div>
            )}
          </div>

          <NavbarToggler onClick={toggleNavbar} />

          <Collapse
            className={classNames(`headerCollapse`, `mt-2`, `mt-lg-0`, `flex-grow-0`)}
            isOpen={!collapsed}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink href="https://support.foodrevolution.org/" target="_blank">
                  Support
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="https://community.foodrevolution.org/" target="_blank">
                  Login
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </Container>
    </header>
  );
}

export function OldHeader({
  className = undefined,
  bgColor = 'black',
  textColor = 'white',
  logoUrl = 'https://cdn.foodrevolution.org/global/frn-logo-2024-trademarked-white.svg',
  logoAlt = 'Food Revolution Network logo',
  logoWidth = 300,
  logoHeight = 126,
  shareUrl,
  shareImg,
  facebookDescription,
  twitterDescription,
  twitterHashtags = undefined,
  pinterestDescription,
  pinterestImage,
  fbLikeUrl = 'https://www.facebook.com/foodrevolutionnetwork',
  navbarStyle = 'dark'
}) {
  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
    <header
      className={classNames(`header`, `background-${bgColor}`, `text-${textColor}`, `d-flex`, `flex-column`, className)}
    >
      <Container className={classNames(`headerContainer`, `my-auto`)}>
        <Navbar className={classNames(`headerNavbar`, `p-0`, `navbar-${navbarStyle}`)} expand="lg" container="false">
          <div className={classNames(`headerNavbarBrand`)}>
            <img
              className={classNames(`headerLogo`)}
              alt={logoAlt}
              src={logoUrl}
              width={logoWidth}
              height={logoHeight}
            />
          </div>

          <NavbarToggler onClick={toggleNavbar} />

          <Collapse className={classNames(`headerCollapse`, `mt-2`, `mt-lg-0`)} isOpen={!collapsed} navbar>
            <Nav navbar>
              <NavItem>
                <NavLink href="https://support.foodrevolution.org/" target="_blank">
                  Support
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="https://community.foodrevolution.org/" target="_blank">
                  Login
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </Container>
    </header>
  );
}
