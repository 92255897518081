import React, {useEffect, useState} from 'react';
import {Container, Col, Row, Button} from 'reactstrap';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';

import {usePhaseState} from 'funnel-schedule/phase-context';
import {useUserState} from 'contexts/user';
import useBalanceText from 'utils/useBalanceText';
import track from 'utils/track';

import PageMeta from 'components/PageMeta';
import Header from 'components/Elements/Header';
import Section from 'components/Elements/Section';
import Webinar from 'components/Webinar';
import Video from 'components/Video';
import SalesCopy from 'components/SalesCopy';
import RegistrationModal from 'components/RegistrationModal';
import ModalExitMasterclassScreening from 'components/Modals/ModalExitMasterclassScreening';
import Footer from 'components/Elements/Footer';

const COMPLETED_MASTERCLASS_EVENT = 'Completed Watching PBCC Masterclass';

export default function ScreeningIndex({replayOverride = false, ...props}) {
  const pageTitle = 'Watch the FREE Plant-Based Coaching Masterclass';
  const pageDescription =
    'Learn how to help people reach their health goals — improving human lives and our planet for generations to come.';
  const ogImage = 'https://cdn.foodrevolution.org/pbcc/og/pbcc-2024-open-graph-masterclass-screening-1200x1200.png';
  const shareUrl = 'https://certification.foodrevolution.org/masterclass/';
  const facebookDescription =
    'Ready to learn how to help people eat healthy & spread the food revolution as a Plant-Based Coach? Register for free here:';
  const twitterDescription =
    'Ready to learn how to help people eat healthy & spread the food revolution as a Plant-Based Coach? Register for free here:';
  const twitterImg = 'https://cdn.foodrevolution.org/pbcc/og/pbcc-2024-open-graph-masterclass-screening-1200x675.png';
  const pinterestDescription =
    'Ready to learn how to help people eat healthy & spread the food revolution as a Plant-Based Coach? Pin to inspire others to join the movement! Register for free here:';
  const pinterestImg =
    'https://cdn.foodrevolution.org/pbcc/og/pbcc-2024-open-graph-masterclass-screening-1000x1500.png';

  const {
    phases: {
      masterclass: masterclassSchedule = {
        phase: 'before'
      },
      webinar: webinarSchedule = {
        phase: 'before'
      }
    }
  } = usePhaseState();

  const [learnMoreControl, setlearnMoreControl] = useState(props.learnMoreControl || 'control');
  useEffect(() => {
    subscribeToCampaign((variantIndex) => {
      switch (variantIndex) {
        case 1:
          setlearnMoreControl('v1');
          break;
      }
    }, 1172);
  }, []);

  const beforeMasterclassLaunch = masterclassSchedule.phase === 'beforeLaunch';

  const {events = []} = useUserState();
  const hasCompletedMasterclass =
    events.includes(COMPLETED_MASTERCLASS_EVENT) ||
    webinarSchedule.phase === 'replay' ||
    webinarSchedule.phase === 'after';

  const productId = 'pbcc';
  const productSku = 'pbcc';
  const productName = 'Plant-Based Coaching Certification';
  const productSlug = 'pbcc-2025-deposit-m';
  const productValue = 100;

  const videoLabel = 'PBCC-M - Masterclass';

  const countdownTime = webinarSchedule.endOfSalesDay * 1000;
  const timerDate = new Date(countdownTime).getTime();

  useEffect(() => {
    if (hasCompletedMasterclass)
      track('Product Viewed', {
        product_id: productId,
        sku: productSku,
        name: productName,
        variant: productSlug,
        value: productValue,
        currency: 'usd'
      });
  }, [hasCompletedMasterclass]);

  useBalanceText();
  return (
    <div className="page page-nosocial d-flex flex-column">
      <PageMeta title={pageTitle} description={pageDescription} url={shareUrl} ogImage={ogImage} />

      <div className="page-content">
        <Header
          bgColor="purple"
          lockupWhite
          logoUrlOverride="https://cdn.foodrevolution.org/pbcc/pbcc-logo-masterclass-white.svg"
          logoAltOverride="plant-based coaching masterclass logo"
          logoWidthOverride={300}
          logoHeightOverride={59}
          shareUrl={shareUrl}
          shareImg={ogImage}
          facebookDescription={facebookDescription}
          twitterDescription={twitterDescription}
          pinterestDescription={pinterestDescription}
          pinterestImage={pinterestImg}
        />

        {beforeMasterclassLaunch ? (
          <Section
            id="screening-header-cta"
            color="white"
            className="section-angle-first"
            angle={replayOverride ? 'bottom' : 'none'}
          >
            <Container>
              <Row className="text-center">
                <Col>
                  <h1 className="section-heading text-h2 mb-3">The Plant-Based Coaching Masterclass</h1>
                  <p className="balance-text">
                    Are you ready to be a LEADER in the food revolution movement? In this FREE Masterclass, you’ll learn
                    why now is the best time to get certified as a Plant-Based Coach and be an advocate for change.
                  </p>
                  <div className="background-light-gray rounded p-4 p-lg-5">
                    <h3 className="section-only-heading">Coming Soon!</h3>
                  </div>
                </Col>
              </Row>
            </Container>
          </Section>
        ) : (
          <>
            <Section
              id="screening-header-cta"
              color="white"
              className="section-angle-first"
              angle={(hasCompletedMasterclass && !replayOverride) || replayOverride ? 'bottom' : 'none'}
            >
              <Container>
                <Row className="text-center">
                  <Col>
                    <h1 className="section-heading text-h2 mb-3">The Plant-Based Coaching Masterclass</h1>
                    <p className="balance-text">
                      Are you ready to be a LEADER in the food revolution movement? In this FREE Masterclass, you’ll
                      learn how plant-based coaching could change your life… and help you change the world.
                    </p>
                  </Col>
                </Row>
                <Row className="justify-content-center mt-4">
                  <Col lg="10" className="video-col px-0 px-sm-3">
                    {replayOverride ? (
                      <Video
                        url="https://customer-ros8xhsmbkksflna.cloudflarestream.com/913e9c753dad012e1c9b1394cf2f6fcd/watch"
                        label={videoLabel}
                      />
                    ) : (
                      <Webinar noun="Masterclass" label={videoLabel} />
                    )}
                  </Col>
                </Row>
                <Row className="text-center mt-5">
                  <Col>
                    <h5 className="section-heading mb-3">Download your FREE Workbook</h5>
                    <p className="balance-text">
                      Use this workbook to get the MOST out of the Plant-Based Coaching Masterclass
                    </p>
                    <Button
                      color="cta"
                      href="https://cdn.foodrevolution.org/members/pdf/pbcc/Plant-Based%20Coaching%20Masterclass%20-%20Workbook.pdf"
                      target="_blank"
                      onClick={() => {
                        track('Button Clicked', {
                          label: 'Download My Workbook',
                          url: 'https://cdn.foodrevolution.org/members/pdf/pbcc/Plant-Based%20Coaching%20Masterclass%20-%20Workbook.pdf'
                        });
                      }}
                    >
                      Download My Workbook
                    </Button>

                    {learnMoreControl === 'v1' && (
                      <div className="mt-4">
                        <Button
                          color="green"
                          className="btn-learn-more"
                          href="/join/"
                          target="_blank"
                          onClick={() => {
                            track('Button Clicked', {
                              label: 'CLICK HERE to learn more about getting CERTIFIED',
                              url: 'https://certification.foodrevolution.org/join/'
                            });
                          }}
                        >
                          CLICK HERE to learn more about getting CERTIFIED
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
              </Container>
            </Section>

            {hasCompletedMasterclass && !replayOverride ? (
              <>
                <Section id="" color="black" className="text-center text-white">
                  <Container>
                    <Row>
                      <Col>
                        <h2 className="section-heading mb-3">Plant-Based Coaching Certification</h2>
                        <h3 className="m-0">
                          <i>Bring the food revolution to the world</i>
                        </h3>
                      </Col>
                    </Row>
                  </Container>
                </Section>

                <SalesCopy version="masterclass" variant="default" timerDate={timerDate} />
              </>
            ) : null}
          </>
        )}
      </div>

      {replayOverride ? (
        <Section
          id="replay-cta"
          color="purple"
          innerClass="text-center text-white section-webinar-inner-cta pb-5"
          angle="none"
        >
          <Container>
            <Row>
              <Col>
                <h4 className="section-heading">Are you ready to be a LEADER in the food revolution movement?</h4>
                <h4 className="mt-0">Get certified as a Plant-Based Coach and be an advocate for change.</h4>
                <h4 className="mt-0 mb-5">Enrollment is open once per year and is limited to only 300 participants.</h4>
                <Button className="btn-join btn-lg" color="cta" href="/join/" target="_blank">
                  Learn More
                </Button>
              </Col>
            </Row>
          </Container>
        </Section>
      ) : (
        <>
          <RegistrationModal />
          <ModalExitMasterclassScreening />
        </>
      )}

      <div className="footer-content mt-auto">
        <Footer />
      </div>
    </div>
  );
}
